/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { LoadingOutlined } from '@ant-design/icons';
import { useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { ColumnsType } from 'antd/es/table';

import { Node, NodeConfig, NodeFilters, PaginatedNodesResponse, Tag } from '@/client';
import { MimicTranslationFunction, useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Card } from '@/primitives/Card';
import { Flex } from '@/primitives/Flex';
import { OsIcon } from '@/primitives/Icons';
import { Link } from '@/primitives/Link';
import { Text } from '@/primitives/Text';
import { Tooltip } from '@/primitives/Tooltip';
import { UpdateParams } from '@/utils/params';
import MimicHighlighter from '@/v1/components/MimicHighlighter';
import { PaginatedTable } from '@/v1/components/PaginatedTable';
import { RelativeTime } from '@/v1/components/RelativeTime';
import { ConnectivityStateBadge } from '@/v1/components/States/ConnectivityStateBadge';
import { TagsList } from '@/v1/components/TagsList';
import { useSubscribeToEvents } from '@/v1/utils/hooks/useSubscribeToEvents';

export type NodeConfigNodesProps = {
  tenantID: string;
  nodeConfig: NodeConfig;
  nodesQuery: UseQueryResult<PaginatedNodesResponse, Error>;
  nodesFilters: NodeFilters;
  updateQueryParams: UpdateParams<Node, NodeFilters>;
  tags: Tag[] | undefined;
};

export function NodeConfigNodes({
  tenantID,
  nodeConfig,
  nodesQuery,
  nodesFilters,
  updateQueryParams,
  tags,
}: NodeConfigNodesProps) {
  const { t } = useMimicTranslation('nodeConfigurationDetail');
  const queryClient = useQueryClient();

  useSubscribeToEvents(['node-config:assigned'], (response: { nodeConfigID: string }) => {
    if (response.nodeConfigID === nodeConfig.id) {
      queryClient.invalidateQueries({ queryKey: ['nodes', tenantID] });
    }
  });

  const columns = getNodesColumns(tenantID, nodeConfig, t, nodesFilters, tags);
  return (
    <Card padding="md" gap="sm">
      <PaginatedTable
        columns={columns}
        paginatedResource={nodesQuery}
        rowKey={(node: Node) => node.id}
        onParamsChange={updateQueryParams}
      />
    </Card>
  );
}

function getNodesColumns(
  tenantID: string,
  nodeConfig: NodeConfig,
  t: MimicTranslationFunction<'nodeConfigurationDetail'>,
  filters: NodeFilters,
  tagsList?: Tag[],
): ColumnsType<Node> {
  return [
    {
      title: <span data-testid="hostName">{t('assignedNodes.table.header.hostName')}</span>,
      dataIndex: 'hostname',
      sorter: true,
      filterMode: 'menu',
      render: (hostname: string, node: Node) => {
        return (
          <Link
            to={`/tenants/${tenantID}/nodes/${node.id}`}
            style={{ fontSize: 'inherit', fontFamily: 'DM Mono' }}
            dataTestId="node-link"
          >
            <MimicHighlighter searchText={filters?.hostname} text={hostname} />
          </Link>
        );
      },
    },
    {
      title: <span data-testid="tags">{t('assignedNodes.table.header.tags')}</span>,
      dataIndex: 'tags',
      filters: tagsList?.map((tag) => {
        return { text: tag.name, value: tag.name };
      }),
      filterMode: 'menu',
      filterSearch: true,
      width: '200px',
      render: (tags: string[]) => {
        return <TagsList tags={tags} />;
      },
    },
    {
      title: <span data-testid="nodeVersion">{t('assignedNodes.table.header.nodeVersion')}</span>,
      dataIndex: 'nodeVersion',
      render: (nodeVersion: string) => {
        return (
          <Text type="default" size="md">
            {nodeVersion}
          </Text>
        );
      },
    },
    {
      title: <span data-testid="os">{t('assignedNodes.table.header.os')}</span>,
      dataIndex: 'operatingSystem',
      sorter: true,
      filterSearch: true,
      filterMode: 'menu',
      render: (os: Node['operatingSystem'], node: Node) => {
        return (
          <Tooltip title={`${os} ${node.operatingSystem}`} placement="right">
            <div data-testid="operating-system-icon">
              <OsIcon name={os} />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: <span data-testid="connectivity">{t('assignedNodes.table.header.connectivity')}</span>,
      dataIndex: 'connectivityState',
      sorter: true,
      filterSearch: true,
      filterMode: 'menu',
      render: (_, node: Node) => {
        return <ConnectivityStateBadge state={node.connectivityState} />;
      },
    },
    {
      title: <span data-testid="revision">{t('assignedNodes.table.header.revision')}</span>,
      dataIndex: 'appliedConfiguration',
      sorter: true,
      render: (_, node: Node) => {
        const configurationInProcess = node.requestedRevisionState?.status === 'pending';
        return configurationInProcess ? (
          <Flex align="center" gap="xs">
            <LoadingOutlined />
            <Text type="default" size="md">
              {t('assignedNodes.table.processing')}
            </Text>
          </Flex>
        ) : (
          <Link
            to={`/tenants/${tenantID}/node-configs/${nodeConfig.id}/revisions/${node.appliedRevisionState?.configRevision?.revisionNumber}`}
          >
            <Text type="link" size="md">
              {`Revision #${node.appliedRevisionState?.configRevision?.revisionNumber}: ${node.appliedRevisionState?.configRevision?.description}`}
            </Text>
          </Link>
        );
      },
    },
    {
      title: <span data-testid="configuredBy">{t('assignedNodes.table.header.configuredBy')}</span>,
      dataIndex: 'assignedBy',
      filterSearch: true,
      filterMode: 'menu',
      render: (_, node: Node) => {
        return (
          <Text type="default" size="md">
            {node.appliedRevisionState?.assignedBy?.displayName}
          </Text>
        );
      },
    },
    {
      title: <span data-testid="configuredAt">{t('assignedNodes.table.header.configured')}</span>,
      dataIndex: 'dateConfigRevisionApplied',
      sorter: true,
      render: (_, node: Node) => {
        if (!node.appliedRevisionState?.statusDate) return null;
        return <RelativeTime date={node.appliedRevisionState?.statusDate} />;
      },
    },
  ];
}
