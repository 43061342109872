/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQueryClient } from '@tanstack/react-query';
import { ColumnType } from 'antd/es/table';
import { useState } from 'react';

import { ConfigRevision, NodeConfig } from '@/client';
import { MimicTranslationFunction, useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Card } from '@/primitives/Card';
import { Flex } from '@/primitives/Flex';
import { Radio } from '@/primitives/Radio';
import { Text } from '@/primitives/Text';
import { GuardedLink } from '@/v1/components/GuardedLink';
import { PaginatedTable } from '@/v1/components/PaginatedTable';
import { RelativeTime } from '@/v1/components/RelativeTime';
import { useGetConfigRevisionsQuery } from '@/v1/utils/hooks/useGetConfigRevisionsQuery';
import { useSubscribeToEvents } from '@/v1/utils/hooks/useSubscribeToEvents';

import { ConfigurationDetailsRevisionCode } from './ConfigurationDetailsRevisionCode';
import './ConfigurationDetailsRevisions.css';

export type ConfigurationDetailsRevisionsProps = {
  tenantID: string;
  nodeConfig: NodeConfig;
};

export function ConfigurationDetailsRevisions({ tenantID, nodeConfig }: ConfigurationDetailsRevisionsProps) {
  const { t } = useMimicTranslation('nodeConfigurationDetail');
  const queryClient = useQueryClient();

  const [selectedRevisionID, setSelectedRevisionID] = useState<string | null>(null);
  const [showRevisionCode, setShowRevisionCode] = useState(false);

  const onClose = () => {
    setShowRevisionCode(false);
  };

  const onOpen = () => {
    setShowRevisionCode(true);
  };

  const configRevisionsQuery = useGetConfigRevisionsQuery({
    tenantId: tenantID,
    configId: nodeConfig.id,
    filters: {},
    sort: [],
    settings: { number: 1, size: 10 },
  });

  useSubscribeToEvents(['node-config-revision:created'], (response: { nodeConfigID: string }) => {
    if (response.nodeConfigID === nodeConfig.id) {
      queryClient.invalidateQueries({ queryKey: ['config-revisions', nodeConfig.id] });
    }
  });

  const allColumns = getRevisionColumns(tenantID, nodeConfig.id, selectedRevisionID, t, setSelectedRevisionID, onOpen);

  const selectColumns = allColumns.slice(0, 3);

  const selectedRevision = configRevisionsQuery.data?.data?.find((revision) => revision.id === selectedRevisionID);

  if (!showRevisionCode) {
    return (
      <Card padding="md" gap="sm">
        <PaginatedTable
          columns={allColumns}
          paginatedResource={configRevisionsQuery}
          rowKey={(revision: ConfigRevision) => revision.id}
        />
      </Card>
    );
  }

  return (
    <Card padding="md" gap="sm">
      <Flex full>
        <div style={{ width: '50%' }}>
          <PaginatedTable
            columns={selectColumns}
            paginatedResource={configRevisionsQuery}
            rowKey={(revision: ConfigRevision) => revision.id}
          />
        </div>
        <ConfigurationDetailsRevisionCode configRevision={selectedRevision} onClose={onClose} />
      </Flex>
    </Card>
  );
}

function getRevisionColumns(
  tenantID: string,
  configID: string,
  selectedRevisionID: string | null,
  t: MimicTranslationFunction<'nodeConfigurationDetail'>,
  setSelectedRevisionID: (revisionID: string) => void,
  onOpen: (isOpen: boolean) => void,
) {
  const columns: ColumnType<ConfigRevision>[] = [
    {
      dataIndex: 'select',
      width: '44px',
      render: (_, revision: ConfigRevision) => {
        return (
          <Radio
            dataTestId="select-config-revision"
            checked={selectedRevisionID === revision.id}
            onChange={() => setSelectedRevisionID(revision.id)}
            onClick={() => onOpen(true)}
          />
        );
      },
    },
    {
      title: <span data-testid="revisionNumber">#</span>,
      dataIndex: 'revisionNumber',
      width: '72px',
      render: (_, revision: ConfigRevision) => {
        return (
          <Text type="default" size="md" className={selectedRevisionID === revision.id ? 'selected' : ''}>
            {revision.revisionNumber}
          </Text>
        );
      },
    },
    {
      title: <span data-testid="revisionDescription">{t('revisionsTable.revisionDesription')}</span>,
      dataIndex: 'description',
      render: (_, revision: ConfigRevision) => {
        return (
          <GuardedLink
            to={`/tenants/${tenantID}/node-configs/${configID}/revisions/${revision.revisionNumber}`}
            style={{ fontSize: 'inherit', fontFamily: 'DM Mono' }}
            dataTestId="revision-link"
            requiredRole="editor"
          >
            <Text type="link" size="md">
              {revision.description}
            </Text>
          </GuardedLink>
        );
      },
    },
    {
      title: <span data-testid="author">{t('revisionsTable.author')}</span>,
      dataIndex: 'author',
      width: '172px',
      render: (_, revision: ConfigRevision) => {
        return (
          <Text type="default" size="md">
            {revision.createdBy.displayName}
          </Text>
        );
      },
    },
    {
      title: <span data-testid="created">{t('revisionsTable.created')}</span>,
      dataIndex: 'createdAt',
      width: '172px',
      render: (_, revision: ConfigRevision) => {
        return (
          <Text type="default" size="md">
            <RelativeTime date={revision.createdAt} />
          </Text>
        );
      },
    },
  ];

  return columns;
}
